import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { KeycloakSecurityService } from './keycloak-security.service';
import { KeycloakProfile } from 'keycloak-js';

@Injectable({
    providedIn: 'root'
})
export class AuthenticatedKeycloak {
    private url = environment.urlKeycloak;
    private url2 = environment.urlLambda;
    public userProfile: any;

    roles: any[] = [];
    is_superadmin: Observable<boolean> = of(false);
    is_propietario: Observable<boolean> = of(false);
    is_admin: Observable<boolean> = of(false);
    is_profesional: Observable<boolean> = of(false);
    is_asistente: Observable<boolean> = of(false);
    is_paciente: Observable<boolean> = of(false);
    is_upi: Observable<boolean> = of(false);

    constructor(protected _http: HttpClient, public securityService: KeycloakSecurityService) {
        this.roles = localStorage.getItem(`azh_${environment.name}_roles_from_support`) ? JSON.parse(localStorage.getItem(`azh_${environment.name}_roles_from_support`)) : [];

        if (!this.roles.length) {
            this.getUserObjectMe().then((me) => {
                this.roles = me.perfil;
                if (this.roles) {
                    localStorage.setItem(`azh_${environment.name}_rols`, JSON.stringify(this.roles));
                }
                this.loadInit();
            });
        } else {
            this.loadInit();
        }
    }

    private loadInit() {
        this.isSuperAdmin();
        this.isPropietario();
        this.isAdmin();
        this.isProfesional();
        this.isAsistente();
        this.isUpi();

        if (this.roles && this.roles.length <= 1) {
            this.roles.forEach(rol => {
                let value: String = typeof rol == 'object' ? rol.nombre : rol
                if (value.toLowerCase() === 'paciente') {
                    this.isPaciente();
                }
            });
        }
    }

    public isSuperAdmin(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            if (this.roles && this.roles.length) {
                this.roles.forEach(rol => {
                    let value: String = typeof rol == 'object' ? rol.nombre : rol
                    if (value.toLowerCase() === "super_admin") {
                        this.is_superadmin = of(true);
                        resolve(true);
                    }
                });
            }

            resolve(false);
        });
    }

    public isUpi(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            if (this.roles && this.roles.length) {
                this.roles.forEach(rol => {
                    let value: String = typeof rol == 'object' ? rol.nombre : rol
                    if (value.toLowerCase() === "upi") {
                        this.is_upi = of(true);
                        resolve(true);
                    }
                });
            }

            resolve(false);
        });
    }

    public isPropietario(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            if (this.roles && this.roles.length) {
                this.roles.forEach(rol => {
                    let value: String = typeof rol == 'object' ? rol.nombre : rol
                    if (value.toLowerCase() === "propietario") {
                        this.is_propietario = of(true);
                        resolve(true);
                    }
                });
            }

            resolve(false);
        });
    }

    public isAdmin(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            if (this.roles && this.roles.length) {
                this.roles.forEach(rol => {
                    let value: String = typeof rol == 'object' ? rol.nombre : rol
                    if (value.toLowerCase() === "admin") {
                        this.is_admin = of(true);
                        resolve(true);
                    }
                });
            }

            resolve(false);
        });
    }

    public isProfesional(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            if (this.roles && this.roles.length) {
                this.roles.forEach(rol => {
                    let value: String = typeof rol == 'object' ? rol.nombre : rol
                    if (value.toLowerCase() === "profesional") {
                        this.is_profesional = of(true);
                        resolve(true);
                    }
                });
            }

            resolve(false);
        });
    }

    public isAsistente(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            if (this.roles && this.roles.length) {
                this.roles.forEach(rol => {
                    let value: String = typeof rol == 'object' ? rol.nombre : rol
                    if (value.toLowerCase() === "asistente") {
                        this.is_asistente = of(true);
                        resolve(true);
                    }
                });
            }

            resolve(false);
        });
    }

    public isPaciente(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            if (this.roles && this.roles.length) {
                this.roles.forEach(rol => {
                    let value: String = typeof rol == 'object' ? rol.nombre : rol
                    if (value.toLowerCase() === "paciente") {
                        this.is_paciente = of(true);
                        resolve(true);
                    }
                });
            }

            resolve(false);
        });
    }

    public getImageClinica() {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this._http.get(`${this.url2}services.cliente/getlogo`, { headers: headers });
    }

    public validarPasswordOld(iduser: number, pass: string) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this._http.get(`${this.url2}services.acciones/validpassold/${iduser}/${pass}`, { headers: headers });
    }

    public changePass(data: any) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this._http.post(`${this.url2}services.acciones/changepassword/`, data, { headers: headers });
    }

    public getTokenInfoServices(token: string): Observable<any> | any {
        let accessToken = token;
        const headerSettings: { [name: string]: string | string[] } = {};
        headerSettings['authorization'] = 'Bearer ' + accessToken;
        return this._http.get(`${this.url2}services.profile/token-me/userinfo`, { headers: headerSettings });
    }

    public getUserObjectMe(): Promise<any> {
        let tokenStorage = localStorage.getItem(`azh_${environment.name}_token__from_support`);

        return new Promise((resolve, reject) => {
            if (tokenStorage == null) {
                this.securityService.kc.loadUserInfo().then((result: any) => {
                    let me = result.me;
                    resolve(me);
                }).catch((err) => {
                    reject(err);
                });
            } else {
                this.getTokenInfoServices(tokenStorage).subscribe(
                    (result: any) => {
                        if (result) {
                            let me = result.retObject;
                            let data = me;
                            resolve(data);
                        }
                    }, (error: HttpErrorResponse) => {
                        reject(error);
                    }
                );
            }
        });
    }

    public logout() {
        let URI = `${window.location.protocol}//${window.location.host}/inicio`;

        if (window.location.protocol === 'https:') {
            URI = `${window.location.protocol}//${window.location.host}/inicio`;
        }
        
        localStorage.removeItem(`azh_${environment.name}_token__from_support`);
        localStorage.removeItem(`azh_${environment.name}_roles_from_support`);
        localStorage.removeItem(`azh_${environment.name}_rols`);

        this.securityService.kc.logout({ redirectUri: URI });
    }
}
