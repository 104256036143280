import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../../services/profile.service';
import { ValidatorsUtils } from '../../utils/validators.util';
import Utils from '../utils/utils.component';

@Component({
    selector: 'app-download-data',
    templateUrl: './download-data.component.html',
    styleUrls: ['./download-data.component.css'],
    providers: []
})
export class DownloadDataComponent implements OnInit {
    public readonly BASE_TRANSLATE = "download_data";
    public readonly BASE_TRANSLATE_SHARED = "shared";

    isLoadingGroups: boolean = false;
    isLoadingProfile: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<DownloadDataComponent>,
        private profileService: ProfileService,
        public utils: ValidatorsUtils,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        private util: Utils
    ) { }

    async ngOnInit() { 
        this.util.saveDataAudit('Exitoso','Accedio a la ventana descargar información');
    }

    async downloadGroups() {
        this.isLoadingGroups = true;

        this.profileService.downloadGroups().subscribe((res: any) => {
            this.isLoadingGroups = false;
            if (res.retCode === '0') {
                this.util.saveDataAudit('Exitoso','Datos del grupo descargado correctamente');
                this.utils.downloadFile(res.retObject.bytes.replace('data:text/csv;base64,', ''), res.retObject.nameFile, res.retObject.formatType, "csv")
            } else {
                this.util.saveDataAudit('Error','Error al dercargar los datos del grupo',res);
                this.snackBar.open(res.retMessage, '', { panelClass: 'warn' });
            }
        }, error => {
            console.error(error);
            this.util.saveDataAudit('Error','Error al descargar los datos del grupo',error);
            this.isLoadingGroups = false;
            if (error.error.retMessage) {
                this.snackBar.open(error.error.retMessage, '', { panelClass: 'warn', duration: 3000 });
            } else {
                this.snackBar.open(this.translate.instant('controlPrograma.message.sms_1'), '', { panelClass: 'error', duration: 2000 });
            }
        });

    }

    async downloadProfile() {
        this.isLoadingProfile = true;

        this.profileService.downloadProfile().subscribe((res: any) => {
            this.isLoadingProfile = false;
            if (res.retCode === '0') {
                this.util.saveDataAudit('Exitoso','Datos del usuario descargado correctamente');
                this.utils.downloadFile(res.retObject.bytes.replace('data:application/pdf;base64,', ''), res.retObject.nameFile, res.retObject.formatType, "pdf")
            } else {
                this.util.saveDataAudit('Error','Error al descargar los datos del usuario',res);
                this.snackBar.open(res.retMessage, '', { panelClass: 'warn' });
            }
        }, err => {
            console.error(err);
            this.util.saveDataAudit('Error','Error al descargar los datos del usuario',err);
            this.isLoadingProfile = false;
            this.snackBar.open(this.translate.instant(this.BASE_TRANSLATE + '.sms_err1'), '', { panelClass: 'error', duration: 2000 });
        });

    }
}