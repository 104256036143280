import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: "root"
})
export class AuditService {
  private headers = new HttpHeaders({'Accept': 'application/json' });
  private url: string;

  constructor(private _http: HttpClient) {
    this.url = environment.urlLambda;
  }

  getUsersService() {
    let serviceURL = `${this.url}services.superadmin/audit-users-simple-list`;
    return this._http.get(serviceURL, { headers: this.headers });
  }
  
  getAuditService(page: number, pagesize: number, user: string, start: string, end: string) {
    let serviceURL = `${this.url}services.superadmin/audit-list-pagination/${page}/${pagesize}/${user}/${start}/${end}`;
    return this._http.get(serviceURL, { headers: this.headers });
  }

  postAudit(data: any) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let serviceURL = `${this.url}services.audit/addMassive`;
    localStorage.removeItem('jsonAuditAzh');
    return this._http.post<any>(serviceURL, data, { headers: this.headers });
  }
}
