<i class="fas fa-times cerrar-modal" (click)="this.dialogRef.close(false);"></i>
<h4 mat-dialog-title class="titulo-modal">{{ BASE_TRANSLATE + '.title' | translate }}</h4>
<form *ngIf="!loading" [formGroup]="form" class="form" style="width: 700px !important;">
	<mat-dialog-content>
		<div class="mdl-grid">
			<div class="mdl-cell mdl-cell--4-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
				<div class="wrapper-photo">
					<div (click)="openChangePhoto()" class="photo">
						<img [src]="avatar != '' ? avatar : 'assets/img/avatar.png'" alt="none">
					</div>
					<div class="tooltip">
						<button type="button" [disabled]="avatar != '' || cargaservice2 ? false : true"
							(click)="avatar != '' ? avatarDefult() : ''"
							class="mdl-button mdl-button--reload mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent download-button"
							style="position: relative; right: 0; bottom: 0; z-index: 10;"><i *ngIf="!cargaservice2"
								class="fas fa-sync"></i>
							<az-loader-button [show-loading]="cargaservice2" [color]="'var(--texto-boton)'">
							</az-loader-button>
						</button>
						<span *ngIf="avatar != ''" class="tooltiptext">{{ BASE_TRANSLATE + '.text_image_default' |
							translate }}</span>
					</div>
				</div>
			</div>
			<div class="mdl-cell mdl-cell--8-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
				<div class="mdl-grid">
					<div class="mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
						<div class="form-group">
							<mat-label>{{ BASE_TRANSLATE_SHARED + '.email/username' | translate }}</mat-label>
							<input disabled readonly class="form-control" [value]="info?.email ?? '---'" type="text">
						</div>
					</div>
					<div class="mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
						<div class="form-group">
							<mat-label>{{ BASE_TRANSLATE + '.form.input_name' | translate }}</mat-label>
							<input class="form-control" type="text" [ngModel]="info?.nombre" formControlName="name"
								(keyup)="getValueSoloTexto($event, 'name')"
								(keydown)="getValueSoloTexto($event, 'name')">
						</div>
					</div>
					<div class="mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
						<div class="form-group">
							<mat-label>{{ BASE_TRANSLATE + '.form.input_paternal_lastname' | translate }}</mat-label>
							<input class="form-control" type="text" [ngModel]="info?.apellidopaterno" formControlName="paternallasname"
								(keyup)="getValueSoloTexto($event, 'paternallasname')"
								(keydown)="getValueSoloTexto($event, 'paternallasname')">
						</div>
					</div>
					<div class="mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
						<div class="form-group">
							<mat-label>{{ BASE_TRANSLATE + '.form.input_maternal_lastname' | translate }}</mat-label>
							<input class="form-control" type="text" [ngModel]="info?.apellidomaterno" formControlName="maternallastname"
								(keyup)="getValueSoloTexto($event, 'maternallastname')"
								(keydown)="getValueSoloTexto($event, 'maternallastname')">
						</div>
					</div>
					<div class="mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
						<div class="form-group">
							<mat-select [placeholder]="(BASE_TRANSLATE + '.form.input_sex' | translate)"
								ngDefaultControl [ngModel]="info?.genero" formControlName="sex" class="form-control">
								<mat-option *ngFor="let s of listSex" [value]="s.value">
									{{ s.viewValue }}
								</mat-option>
							</mat-select>
						</div>
					</div>
					<div [hidden]="!is_paciente"
						class="mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
						<div class="form-group">
							<label class="form-label">{{ BASE_TRANSLATE + '.form.input_date' | translate }}</label>
							<input type="date" [ngModel]="info?.pacfechanacimiento?.split('T')[0]" [min]="minDate" [max]="maxDate" class="form-control"
								pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" formControlName="age">
						</div>
					</div>
					<div [hidden]="!is_paciente"
						class="mdl-cell mdl-cell--4-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
						<div class="form-group">
							<mat-label>{{ BASE_TRANSLATE + '.form.input_estatura' | translate }}</mat-label>
							<input class="form-control" [ngModel]="info?.altura" matInput type="number" formControlName="height" min="1" max="250" minlength="1"
								maxlength="3" (keyup)="validNumberEntero($event)" (keydown)="validNumberEntero($event)">
							<mat-hint>175cm, 200cm...</mat-hint>
						</div>
					</div>
					<div [hidden]="!is_paciente"
						class="mdl-cell mdl-cell--8-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
						<div class="box-checkbox">
							<mat-checkbox class="ml-2 ml-0-table ml-0-phone" [ngModel]="info?.is_atleta" ngDefaultControl
								formControlName="is_atleta">{{ BASE_TRANSLATE + '.form.check_is_atleta' | translate }}
							</mat-checkbox>
							<mat-checkbox *ngIf="form.value.sex === 'F'" class="ml-3 ml-0-table ml-0-phone" [ngModel]="info?.is_gestante"
								ngDefaultControl formControlName="is_gestante">{{ BASE_TRANSLATE +
								'.form.check_is_gestante' | translate }}</mat-checkbox>
						</div>
					</div>
				</div>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button (click)="dialogRef.close()" type="button"
			class="mdl-button mdl-button-cancel close-modal modal__close">{{
			BASE_TRANSLATE_SHARED + '.buttons.cancel' | translate}}</button>
		<button [disabled]="!form.valid || cargaservice" type="button" (click)="form.valid ? submit() : ''"
			class="mdl-button close-modal modal__close">{{
			BASE_TRANSLATE_SHARED + '.buttons.update' | translate }} <az-loader-button [show-loading]="cargaservice"
				[color]="'var(--primario)'" [disabled]="!form.valid || cargaservice ? true : false"></az-loader-button>
		</button>
	</mat-dialog-actions>
</form>
<az-loader-base [show-loading]="loading"></az-loader-base>