import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthenticatedKeycloak } from '../services/authenticated-keycloak.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private router: Router,
    private authenticatedKeycloak: AuthenticatedKeycloak,
  ) { }

  HAS_ACCESS: any;
  canActivate =  async (route: ActivatedRouteSnapshot) => {
    if (localStorage.getItem(`azh_${environment.name}_roles_from_support`)) {
      let currentRoles: any[] = JSON.parse(localStorage.getItem(`azh_${environment.name}_roles_from_support`));
      let rols: string[] = []
      for (const rol of currentRoles) {
        rols.push(rol.nombre);
      }
      this.HAS_ACCESS = rols;
    } else {
      this.HAS_ACCESS = await this.authenticatedKeycloak.getUserObjectMe().then((me) => me);
      this.HAS_ACCESS = this.HAS_ACCESS.perfil.map((item) => item.nombre);
    }
    let next = false;
    const roles = route.data.roles;
    if (!roles || roles.length === 0) {
      next = true;
    } else {
      for (const r of roles) {
        if (this.HAS_ACCESS.indexOf(r) > -1) {
          next = true;
          break;
        }
      }
    }

    if (next) {
      return true;
    } else {
      this.router.navigateByUrl('/inicio');
      return false;
    }
  }
}
