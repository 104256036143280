import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticatedKeycloak } from './services/authenticated-keycloak.service';
import Utils from './shared/components/utils/utils.component';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
	public readonly BASE_TRANSLATE_SHARED = 'shared';
	public environment = environment;
	title = 'Azurian - Health';

	timeout: number = 15;

	constructor(
		public keycloak: AuthenticatedKeycloak,
		private utils: Utils
	) { }

	async ngOnInit() {
		this.keycloak.getUserObjectMe().then((me) => {
			let rol = typeof me.perfil === 'object' ? (me.perfil as { nombre: string, id: number }[]).find((item) => item.nombre).nombre : (me.perfil as string[]).find((item) => item);

			if (rol.toLowerCase() === 'paciente') {
				let x = setInterval(() => {
					this.timeout--;
					if (this.timeout === 0) {
						this.utils.saveDataAudit('Exitoso','Ha cerrado sesión');
        				this.utils.saveAudit();
						this.keycloak.logout();
						clearInterval(x);
					}
				}, 1000);
			}
		});

		setInterval(() => {
			this.utils.saveAudit();
		}, (300000 / 2));
	}
}