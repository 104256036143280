import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AuditService } from 'src/app/superadmin/services/audit.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root"
})
export default class Utils {
  constructor(
    private auditService: AuditService,
    private deviceService: DeviceDetectorService,
    private router: Router,
  ) { }

  static strCapitalize(str): string {
    if (str == null || str === undefined) {
      return '';
    } else {
      return str.replace(
        /\w\S*/g,
        function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    }
  }
  static b64toBlob(b64Data, contentType = '', sliceSize = 512): any {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  static hexToRGB(hex, alpha) {
    let r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  static cleanDate(date: string): string {

    let index = date.indexOf('[');

    if (index > 0) {
      return date.substring(0, index);
    } else {
      return date;
    }
  }

  public static chartColors = {
    red: 'rgb(255, 99, 132)',
    orange: 'rgb(255, 159, 64)',
    yellow: 'rgb(255, 205, 86)',
    green: 'rgb(75, 192, 192)',
    blue: 'rgb(54, 162, 235)',
    purple: 'rgb(153, 102, 255)'
  };

  public static getDaysArrayByMonth(anio, mes) {
    let daysInMonth = moment(`${anio}'-'${mes}`, 'YYYY-MM').daysInMonth();
    let arrDays = [];

    while (daysInMonth) {
      let current = moment(`${anio}'-'${mes}`, 'YYYY-MM').date(daysInMonth);
      arrDays.push(current);
      daysInMonth--;
    }
    return arrDays;
  }

  public async saveDataAudit(status: string, description: string, extra: any = {}) {
    const dataInStorage = !localStorage.getItem('jsonAuditAzh') ? [] : JSON.parse(localStorage.getItem('jsonAuditAzh'));

    let rols = localStorage.getItem(`azh_${environment.name}_roles_from_support`)
      ? !localStorage.getItem(`azh_${environment.name}_roles_from_support`) ? [] : JSON.parse(localStorage.getItem(`azh_${environment.name}_roles_from_support`))
      : !localStorage.getItem(`azh_${environment.name}_rols`) ? [] : JSON.parse(localStorage.getItem(`azh_${environment.name}_rols`));
    
    let jsonAudit = [...dataInStorage];
    jsonAudit.push({
      "estatus": status,
      "description": description,
      "info": extra,
      "rolCurrent": rols,
      "routeCurrent": this.router.url,
      "created_at": moment().format('YYYY-MM-DD HH:mm:ss.ms')
    });

    localStorage.setItem('jsonAuditAzh', JSON.stringify(jsonAudit));
  }

  public saveAudit() {
    let jsonAudit = !localStorage.getItem('jsonAuditAzh') ? [] : JSON.parse(localStorage.getItem('jsonAuditAzh'));
    let json = {
      dataSet: [
        {
          "estatus": "Exitoso",
          "description": "Flujo de Auditoria",
          "platform": "Portal Web - Admin",
          "raw_data": {
            deviceInfo: this.deviceService.getDeviceInfo(),
            data: jsonAudit
          },
          "created_at": moment().format('YYYY-MM-DD HH:mm:ss.ms')
        }
      ],
    }

    if (!jsonAudit.length) return;
    this.auditService.postAudit(json).subscribe(() => { });
  }
}
