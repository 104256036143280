import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../../services/profile.service';
import { environment } from 'src/environments/environment';
import Utils from '../utils/utils.component';

@Component({
    selector: 'app-cambiar-rol',
    templateUrl: './cambiar-rol.component.html',
    styleUrls: ['./cambiar-rol.component.css'],
    providers: []
})
export class CambiarRolComponent implements OnInit {
    public readonly BASE_TRANSLATE = "exchange_rol";
    public readonly BASE_TRANSLATE_SHARED = "shared";
    cargaservice: boolean = false;
    loading: boolean = false;

    form: FormGroup;
    listClient: any[] = []
    listUsers: any[] = []
    constructor(
        public dialogRef: MatDialogRef<CambiarRolComponent>,
        private fb: FormBuilder,
        private translate: TranslateService,
        private snackBar: MatSnackBar,
        private profileService: ProfileService,
        private utils: Utils
    ) {

    }

    ngOnInit() {
        this.utils.saveDataAudit('Exitoso','Accedio a la ventana cambiar rol');
        this.form = this.fb.group({
            idc: ['', [Validators.required]],
            email: ['', [Validators.required]]
        });
        this.getListadoEmpresa();
    }

    getListadoEmpresa() {
        this.loading = true;
        this.profileService.getListadoEmpresa().subscribe((result: any) => {
            this.utils.saveDataAudit('Exitoso','Ventana cambiar rol: empresas obtenidas correctamente',{"nrEmpresas":result.retObject.data.length});
            this.listClient = result.retObject.data;
            this.loading = false;
        }, (err) => {
            this.utils.saveDataAudit('Error','Error al obtener las empresas',err);
            console.log(err);
            this.loading = false;
        });
    }
    listadoUsuarios(event: any) {
        this.listUsers = []
        this.profileService.getListadoUsuariosByIdc(event.value).subscribe((result: any) => {
            this.utils.saveDataAudit('Exitoso','Ventana cambiar rol: usuarios obtenidos correctamente de la empresa '+event.source.triggerValue,{"nrUsuarios":result.retObject.data.length});
            this.listUsers = result.retObject.data;
        }, (err) => {
            this.utils.saveDataAudit('Error','Error al obtener los usuarios de la empresa '+event.source.triggerValue,err);
            console.log(err);
        });
    }

    toLine(a: String[]): String {
        let n: String[] = []
        for (const i of a) {
            if (i != 'upi') {
                n.push(this.translate.instant(this.BASE_TRANSLATE_SHARED + '.navbar.is_' + i))
            } else {
                n.push('UPI')
            }
        }
        return n.join(",")
    }

    capitalizeLetter(__string: string): string {
        let r = "";
        let values = __string.split(" ");
        for (let index = 0; index < values.length; index++) {
            const element = values[index];
            r += String(element || "").charAt(0).toUpperCase() + String(element || "").toLowerCase().slice(1);
            if (index < (values.length - 1)) {
                r += " ";
            }
        }
        return r
    }

    submit() {
        this.cargaservice = true;

        this.profileService.postExchangeRol(this.form.value).subscribe((res: any) => {
            this.utils.saveDataAudit('Exitoso','Rol cambiado correctamente',res.retObject.decode.payload.me);
            this.utils.saveAudit();
            localStorage.setItem(`azh_${environment.name}_token__from_support`, res.retObject.auth.access_token);
            localStorage.setItem(`azh_${environment.name}_roles_from_support`, JSON.stringify(res.retObject.decode.payload.me.perfil));

            this.snackBar.open(this.translate.instant(this.BASE_TRANSLATE + '.message.sms_1'), "", { panelClass: "success", duration: 4500 });
            setTimeout(() => {
                this.reloadPage()
            }, 1500);
        }, (err) => {
            console.log(err);
            this.utils.saveDataAudit('Error','Error al cambiar rol', err);
            this.snackBar.open(this.translate.instant(this.BASE_TRANSLATE + '.message.sms_2'), "", { panelClass: "error", duration: 4500 });
            this.cargaservice = false;
        });
    }
    reloadPage() {
        location.reload();
    }
}