import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NgEventBus } from 'ng-event-bus';
import { ProfileService } from '../../services/profile.service';
import { CropperPhotoComponent } from './components/cropper-photo/cropper-photo.component';
import Utils from '../utils/utils.component';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css'],
    providers: []
})
export class ProfileComponent implements OnInit {
    public readonly BASE_TRANSLATE = "profile";
    public readonly BASE_TRANSLATE_SHARED = "shared";

    minDate: string = `${(parseInt(moment(new Date()).format("YYYY")) - 130)}${moment(new Date()).format("-MM-DD")}`;
    maxDate: string = `${moment(new Date()).format("YYYY-MM-DD")}`;

    form: FormGroup = new FormGroup({});
    cargaservice: boolean = false;
    cargaservice2: boolean = false;
    loading: boolean = false;

    is_paciente: boolean = false;

    listSex: any[] = [];
    avatar: string = "";
    info: any = {
        nombre: "",
        apellidopaterno: "",
        apellidomaterno: "",
        genero: "",
        pacfechanacimiento: "",
        altura: 0,
        is_atleta: false,
        is_gestante: false
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        public dialogRef: MatDialogRef<ProfileComponent>,
        private dialog: MatDialog,
        private fb: FormBuilder,
        private translate: TranslateService,
        private snackBar: MatSnackBar,
        private profileService: ProfileService,
        private eventBus: NgEventBus,
        private utils: Utils
    ) {
        this.listSex = [
            { value: 'F', viewValue: this.translate.instant(`${this.BASE_TRANSLATE}.form.label_f`) },
            { value: 'M', viewValue: this.translate.instant(`${this.BASE_TRANSLATE}.form.label_m`) }
        ];

        this.avatar = data.avatar;
        this.is_paciente = data.isPaciente;
    }

    ngOnInit() {
        this.utils.saveDataAudit('Exitoso','Accedio a la ventana editar perfil');
        this.form = this.fb.group({
            name: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
            paternallasname: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
            maternallastname: ["", [Validators.minLength(3), Validators.maxLength(25)]],
            sex: ["F", [Validators.required]],
            age: [""],
            height: [0, [Validators.minLength(1), Validators.maxLength(3)]],
            is_atleta: [false],
            is_gestante: [false]
        });

        this.getProfile();
        this.initChangeAvatar();
    }

    initChangeAvatar() {
        this.eventBus.on('avatar:user').subscribe((res: any) => {
            if (res._data) {
                this.avatar = res._data.avatar;
            }
        });
    }

    getValueSoloTexto(e: any, name: string) {
        let _v = e.target.value.trimStart();
        _v = e.target.value.replace(
            new RegExp(/[-1234567890°~``*\/?+.,^,_¬$!"#%&=¡¿'´´´¨;:¨><{}()|[\]\\]/g),
            ''
        );
        this.form.get(`${name}`).setValue(_v.trimStart());
        return _v.trimStart();
    }

    validNumberEntero(e: any) {
        let _v2 = e.target.value.trim();
        _v2 = e.target.value.replace(
            new RegExp(/[-°~``*\/?+.,^,_¬$!"#%&=¡¿'´´´¨;:¨><{}()|[\]\\]/g),
            ''
        );
        this.form.get("height").setValue(_v2.trim());
        return _v2.trim();
    }

    openChangePhoto() {
        this.dialog.open(CropperPhotoComponent);
    }

    avatarDefult() {
        this.cargaservice2 = true;
        this.profileService.putAvatar({ img: "" }).subscribe(res => {
            this.utils.saveDataAudit('Exitoso','Imagen por defecto cambiada correctamente');
            this.avatar = "";
            this.eventBus.cast("avatar:user", { avatar: this.avatar });
            this.snackBar.open(this.translate.instant(this.BASE_TRANSLATE + '.message.sms_3'), "", { panelClass: "success", duration: 4500 });
            this.cargaservice2 = false;
        }, (err) => {
            this.utils.saveDataAudit('Error','Error al cambiar la imagen por defecto',err);
            console.log(err);
            this.snackBar.open(this.translate.instant(this.BASE_TRANSLATE + '.message.sms_4'), "", { panelClass: "error", duration: 4500 });
            this.cargaservice2 = false;
        });
    }

    getProfile() {
        this.loading = true;
        this.profileService.getDatos(this.is_paciente).subscribe((result: any) => {
            this.info = result.retObject;
            this.loading = false;
        }, (err) => {
            console.log(err);
            this.loading = false;
        });
    }

    submit() {
        this.cargaservice = true;
        let data: { user?: { nombre?: string, apellidopaterno?: string, apellidomaterno?: string, genero?: string }, paciente?: { pacfechanacimiento?: string, altura?: number, is_gestante?: boolean, is_atleta?: boolean } } = {};

        if (this.is_paciente) {
            data = {
                user: {
                    nombre: this.form.value.name,
                    apellidopaterno: this.form.value.paternallasname,
                    apellidomaterno: this.form.value.maternallastname,
                    genero: this.form.value.sex
                },
                paciente: {
                    pacfechanacimiento: moment(this.form.value.age).format("YYYY-MM-DD"),
                    altura: parseInt(this.form.value.height),
                    is_gestante: this.form.value.sex === 'F' ? this.form.value.is_gestante : false,
                    is_atleta: this.form.value.is_atleta
                }
            }
        } else {
            data = {
                user: {
                    nombre: this.form.value.name,
                    apellidopaterno: this.form.value.paternallasname,
                    apellidomaterno: this.form.value.maternallastname,
                    genero: this.form.value.sex
                }
            }
        }

        this.profileService.putDataUser(data).subscribe((res: any) => {
            if (res.retCode === '0') {
                this.utils.saveDataAudit('Exitoso','Perfil actualizado correctamente');
                this.eventBus.cast("info-profile:user", { ...data });
                this.snackBar.open(res.retMessage, "", { panelClass: "success", duration: 4500 });
                this.cargaservice = false;
                this.dialogRef.close(true);
            } else {
                this.utils.saveDataAudit('Error','Error al actualizar perfil', res);
                this.snackBar.open(this.translate.instant(this.BASE_TRANSLATE + '.message.sms_5'), "", { panelClass: "warn", duration: 4500 });
                this.cargaservice = false;
            }
        }, (err) => {
            console.log(err);
            this.utils.saveDataAudit('Error','Error al actualizar perfil', err);
            this.snackBar.open(this.translate.instant(this.BASE_TRANSLATE + '.message.sms_6'), "", { panelClass: "error", duration: 4500 });
            this.cargaservice = false;
        });
    }
}