import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticatedKeycloak } from 'src/app/services/authenticated-keycloak.service';
import { KeycloakSecurityService } from 'src/app/services/keycloak-security.service';
import Utils from '../utils/utils.component';

@Component({
    selector: 'app-cambiar-clave',
    templateUrl: './cambiar-clave.component.html',
    styleUrls: ['./cambiar-clave.component.css'],
    providers: []
})
export class CambiarClaveComponent implements OnInit {
    public readonly BASE_TRANSLATE = "cambiarClave";
    public readonly BASE_TRANSLATE_SHARED = "shared";

    iduser: number = 0;
    validfrom: boolean = false;
    VALID_PASS_OLD: boolean = false;
    loading: boolean = false;
    cargaservice: boolean = false;
    cargaservice2: boolean = false;
    btn_show_valid: boolean = true;
    show_btn_verificar: boolean = true;

    passold_icon: boolean = true;
    passnew_icon: boolean = true;
    passconfirm_icon: boolean = true;

    passold_error: boolean = false;
    passold_msg_error: string = '';

    passconfirm_error: boolean = false;
    passconfirm_msg_error: string = '';

    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<CambiarClaveComponent>,
        private fb: FormBuilder,
        private securityService: KeycloakSecurityService,
        private keycloak: AuthenticatedKeycloak,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        private utils: Utils
    ) { }

    async ngOnInit() {
        this.utils.saveDataAudit('Exitoso','Accedio a la ventana cambiar contraseña');
        this.keycloak.getUserObjectMe().then((res: any) => {
            this.iduser = res.id;
        }).catch(err => {
            console.log(err);
        });

        this.form = this.fb.group({
            passold: ['', [Validators.required, Validators.minLength(4)]],
            passnew: ['', [Validators.required, Validators.minLength(4)]],
            passconfirm: ['', [Validators.required, Validators.minLength(4)]]
        });

        this.InitValidators();
    }

    async InitValidators() {
        const passconfirm = this.form.get('passconfirm');
        passconfirm.valueChanges.subscribe(value => {
            this.confirmpass(value);
        }
        );

        const passnew = this.form.get('passnew');
        passnew.valueChanges.subscribe(value => {
            this.newpass(value);
        }
        );
    }
    verificarpass(op) {
        if (op == 1) {
            if (this.btn_show_valid && this.show_btn_verificar && String(this.form.value.passold).trim().length >= 3) {
                return true;
            } else {
                return false;
            }
        } else if (op == 2) {
            if (this.validfrom && String(this.form.value.passnew).trim().length >= 3 && String(this.form.value.passconfirm).trim().length >= 3) {
                return false;
            } else {
                return true;
            }
        }
        return false;
    }

    newpass(pass: string) {
        const PASS_CONFIRM = this.form.value.passconfirm;

        if (PASS_CONFIRM != pass) {
            this.passconfirm_msg_error = this.translate.instant(this.BASE_TRANSLATE + '.message.sms_1');
            this.passconfirm_error = true;
            this.validfrom = false;
            return false;
        }

        if (PASS_CONFIRM === pass) {
            this.passconfirm_msg_error = '';
            this.passconfirm_error = false;
            this.validfrom = true;
        }
    }

    confirmpass(pass: string) {
        const PASS_NEW = this.form.value.passnew;

        if (PASS_NEW != pass) {
            this.passconfirm_msg_error = this.translate.instant(this.BASE_TRANSLATE + '.message.sms_1');
            this.passconfirm_error = true;
            this.validfrom = false;
            return false;
        }

        if (PASS_NEW === pass) {
            this.passconfirm_msg_error = '';
            this.passconfirm_error = false;
            this.validfrom = true;
        }
    }

    async validarPasswordOld() {
        this.cargaservice = true;
        this.loading = true;
        this.show_btn_verificar = false;
        const PASS_OLD = this.form.value.passold;

        if (PASS_OLD === '') {
            this.passold_msg_error = this.translate.instant(this.BASE_TRANSLATE + '.message.sms_2');
            this.passold_error = true;

            this.loading = false;
            this.cargaservice = false;
            this.show_btn_verificar = true;

            return false;
        }

        if (PASS_OLD != '') {
            this.passold_msg_error = '';
            this.passold_error = false;

            this.keycloak.validarPasswordOld(this.iduser, PASS_OLD).subscribe((res: any) => {
                if (res.valid === true) {
                    this.utils.saveDataAudit('Exitoso','Contraseña verificada correctamente');
                    this.VALID_PASS_OLD = true;
                    this.form.controls['passold'].disable();

                    this.loading = false;
                    this.cargaservice = false;
                    this.btn_show_valid = false;
                }

                if (res.valid === false) {
                    this.VALID_PASS_OLD = false;
                    this.passold_msg_error = this.translate.instant(this.BASE_TRANSLATE + '.message.sms_3');
                    this.passold_error = true;

                    this.loading = false;
                    this.cargaservice = false;
                    this.show_btn_verificar = true;
                    this.utils.saveDataAudit('Error','Error al verificar la contraseña', res);
                }
            }, err => {
                console.log(err);
                this.utils.saveDataAudit('Error','Error al verificar la contraseña', err);
                if (err.status === 503) {
                    this.snackBar.open(this.translate.instant(this.BASE_TRANSLATE + '.message.sms_4'), '', { panelClass: 'error' });
                }
                this.loading = false;
                this.cargaservice = false;
                this.show_btn_verificar = true;
            });
        }
    }

    async change_pass() {
        this.loading = true;
        this.cargaservice2 = true;
        this.validfrom = false;

        let data = {
            iduser: this.iduser,
            password: this.form.value.passconfirm
        }

        if (this.form.value.passnew.toLowerCase() === this.form.value.passconfirm.toLowerCase() && this.form.value.passnew.toLowerCase() != "" || this.form.value.passconfirm.toLowerCase() != "") {
            this.keycloak.changePass(data).subscribe((res: any) => {
                if (res.retCode === '0') {
                    this.utils.saveDataAudit('Exitoso','Contraseña actualizada correctamente');
                    this.loading = false;
                    this.cargaservice2 = false;
                    this.snackBar.open(this.translate.instant(this.BASE_TRANSLATE + '.message.sms_5'), '', { panelClass: 'success' });

                    setTimeout(() => {
                        let URI = `${window.location.protocol}//${window.location.host}/inicio`;

                        if (window.location.protocol === 'https:') {
                            URI = `${window.location.protocol}//${window.location.host}/inicio`;
                        }

                        this.securityService.kc.logout({ redirectUri: URI });
                    }, 1000);
                } else {
                    this.utils.saveDataAudit('Error','Error al cambiar la contraseña', res);
                    this.snackBar.open(res.retMessage, '', { panelClass: 'warn' });
                }
            }, err => {
                console.log(err);
                this.utils.saveDataAudit('Error','Error al cambiar la contraseña', err);
                this.loading = false;
                this.cargaservice2 = false;
                this.validfrom = true;

                if (err.status < 500) {
                    this.snackBar.open(this.translate.instant(this.BASE_TRANSLATE + '.message.sms_6'), '', { panelClass: 'error' });
                }

                if (err.status === 500) {
                    this.snackBar.open(this.translate.instant(this.BASE_TRANSLATE + '.message.sms_7'), '', { panelClass: 'error' });
                }

                if (err.status === 503) {
                    this.snackBar.open(this.translate.instant(this.BASE_TRANSLATE + '.message.sms_4'), '', { panelClass: 'error' });
                }
            });
        } else {
            this.loading = false;
            this.cargaservice2 = false;
            this.validfrom = true;
        }
    }
}