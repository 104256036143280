<em class="fas fa-times cerrar-modal" (click)="this.dialogRef.close(false);"></em>
<h4 mat-dialog-title class="titulo-modal">{{ BASE_TRANSLATE + '.title' | translate }}</h4>

<mat-dialog-content>

    <div class="mdl-grid" style="width: 400px !important;">
        <div class="mdl-cell mdl-cell--12-col">
            <div class="card-group__graph bbr" (click)="!downloadProfile ? downloadProfile():''">
                <div class="item-download">
                    <span class="title">{{ BASE_TRANSLATE + '.op1' | translate }}</span>
                    <span class="icon"><span *ngIf="!downloadProfile" class="fas fa-arrow-down fa-lg"></span><az-loader-button [show-loading]="downloadProfile" [color]="'var(--primario)'"></az-loader-button></span>
                </div>
            </div>
            <div class="card-group__graph bbr mt-2" (click)="!isLoadingGroups ? downloadGroups():''">
                <div class="item-download">
                    <span class="title">{{ BASE_TRANSLATE + '.op2' | translate }}</span>
                    <span class="icon"><az-loader-button [show-loading]="isLoadingGroups" [color]="'var(--primario)'"></az-loader-button><span *ngIf="!isLoadingGroups" class="fas fa-arrow-down fa-lg"></span></span>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button (click)="dialogRef.close()" type="button" class="mdl-button mdl-button-cancel close-modal modal__close">{{
        BASE_TRANSLATE_SHARED + '.buttons.close' |
        translate}}</button>
</mat-dialog-actions>